import React, { useState } from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";
import Description from "../parts/description/description";
import { Container } from "components/style/layoutStyle";
import { ISOAnswer, ISOImgWrapper, ISOQnaBox } from "./style";
import { IC_PRICING_TOGGLE_ARROW } from "constants/media/icon";

import { useTranslation } from "react-i18next";

const ISO = ({ data, bgColor, height }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const [toggle, setToogle] = useState(false);

    return (
        <Container height={isMobile === "true" ? "auto" : height} bgColor={bgColor} flexDirection={"column"} justifycontent={"center"} padding={isMobile === "true" ? "96px 0px 180px" : "120px 130px 160px"}>
            <ISOImgWrapper isMobile={isMobile}>
                <Image filename={data.img} alt={data.title} style={{ margin: "0" }} />
            </ISOImgWrapper>
            <Description
                padding={isMobile ? "32px 0 0" : "40px 0 0"}
                alignitems={"center"}
                textAlign={"center"}
                type={"normal"}
                title={isMobile === "true" ? t(data.title_mobile) : t(data.title)}
                desc={isMobile === "true" ? t(data.desc_mobile) : t(data.desc)}
                titleGap={"0 0 16px"}
                descGap={"0"}
            />
            <div style={{ padding: "0px 20px", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "relative" }}>
                <ISOQnaBox isMobile={isMobile} onClick={() => setToogle((v) => !v)}>
                    {t("ISO_TITLE")}
                    <Image
                        filename={IC_PRICING_TOGGLE_ARROW}
                        alt={t(data.title)}
                        style={{ margin: "0", marginLeft: isMobile ? "8px" : "16px", width: isMobile ? "20px" : "24px", height: isMobile ? "20px" : "24px", transform: toggle ? "rotate(-180deg)" : "" }}
                    />
                </ISOQnaBox>
                <ISOAnswer toggle={toggle} isMobile={isMobile}>
                    {isMobile === "true" ? t(data.qna.answer_mobile) : t(data.qna.answer)}
                </ISOAnswer>
            </div>
        </Container>
    );
};

export default ISO;
