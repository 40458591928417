import * as React from "react";
import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import Security from "organisms/security";
import { SECURITY_AUTHENTIC, SECURITY_AWS, SECURITY_CERTIFY, SECURITY_TITLE, SECURITY_ISO } from "constants/data/security";
import { SECURITY_META, SECURITY_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const SecurityPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return SECURITY_META_EN;

        return SECURITY_META;
    }, [i18n.language]);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Security title={SECURITY_TITLE} iso={SECURITY_ISO} aws={SECURITY_AWS} auth={SECURITY_AUTHENTIC} certify={SECURITY_CERTIFY} />
        </LayoutBanner>
    );
};

export default SecurityPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
