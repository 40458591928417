import styled from "styled-components";

export const HeaderContainer = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: left;
    background-color: #1e1e1e;
    padding: 0 20px;

    z-index: 9;
`;

export const PrevWrapper = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const PrevButtonImageWrapper = styled.div`
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
`;

export const PdfWrapper = styled.div`
    margin: 30px;
    user-select: none;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
`;

export const ISOImgWrapper = styled.div`
    width: auto;

    ${(props) => (props.isMobile ? `min-width: 355px;` : `min-width: 404px;`)};
`;

export const ISOQnaBox = styled.div`
    width: 468px;
    padding: 12px 0px;

    margin-top: 30px;

    border-radius: 24px;
    background-color: #fff;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.48px;
    text-align: center;
    color: #5a91e0;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${({ isMobile }) =>
        isMobile &&
        `
        width: 100%;

        font-size: 14px;
    `};

    &:hover {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    }
`;

export const ISOQnaDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    margin: 12px 0 14px;
`;

export const ISOQuestion = styled.div`
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: -0.54px;
    text-align: center;
    white-space: pre-line;
    color: #707070;
`;

export const ISOAnswer = styled.div`
    position: absolute;
    top: 100%;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: center;
    color: #707070;
    white-space: pre-line;

    width: 468px;
    margin: 8px 0 0;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transform: scale(0.7);
    overflow: hidden;
    transition: all 0.2s ease;

    ${({ isMobile }) => isMobile && `width: calc(100% - 40px);`};
    ${({ isMobile }) => isMobile && `height: ${isMobile ? "136px" : "104px"}`};

    ${({ toggle, isMobile }) =>
        toggle &&
        `
        opacity: 1;
        transform: scale(1);
        padding: ${isMobile ? "16px 20px 20px" : "20px 53px 24px"};
  `};
`;
