// import { IMG_ISO_MARK, IMG_SECURITY_AWS, IMG_SECURITY_CERTIFI, IMG_SECURITY_DOC, IMG_TOP_BANNER_SECURITY, IMG_TOP_BANNER_SECURITY_MOBILE } from "../media/images";

// // 보안
// export const SECURITY_TITLE = {
//     img: IMG_TOP_BANNER_SECURITY,
//     img_mobile: IMG_TOP_BANNER_SECURITY_MOBILE,
//     title: "이중 보안으로\n더욱 안전하게",
//     desc: "이제 보안 걱정 없이\n계약 업무에 집중하세요."
// };

// export const SECURITY_AWS = {
//     img: IMG_SECURITY_AWS,
//     title: "공신력 있는 데이터 센터로\n문서 보안성을 보장합니다.",
//     desc: "도뉴의 모든 계약서는 국제표준 정보보호 인증 중\n가장 권위있는 ISO27001 인증, PCI DSS Level 1,\nFISMA Moderate, FedRAMP, HIPAA 등\n업계에서 인정하는 보안 인증을 획득한 클라우드 서버\nAWS(Amazon Web Services)에 안전하게 저장 및 백업됩니다.\n\nAWS 데이터 센터는 개인/직원의 방문 및 액세스의 \n엄격한 통제를 통하여 완전무결한 보안성을 보장합니다.",
//     desc_mobile:
//         "도뉴의 모든 계약서는 국제표준 정보보호 인증 중\n가장 권위있는 ISO27001 인증, PCI DSS Level 1,\nFISMA Moderate, FedRAMP, HIPAA 등\n업계에서 인정하는 보안 인증을 획득한\n클라우드 서버 AWS(Amazon Web Services)에\n안전하게 저장 및 백업됩니다.\n\nAWS 데이터 센터는 개인/직원의\n방문 및 액세스의 엄격한 통제를 통하여\n완전무결한 보안성을 보장합니다."
// };

// export const SECURITY_AUTHENTIC = {
//     img: IMG_SECURITY_DOC,
//     title: "블록체인 기술로\n계약의 위변조를\n사전 방지합니다.",
//     desc: "도뉴에서 체결한 모든 계약서는 고유값을 갖게 되며,\n이 고유값은 도뉴의 블록체인에 등록됩니다.\n블록체인에 등록된 고유값은 해킹 및 수정이\n절대 불가하기 때문에 강력한 계약서\n위변조 보안 장치로 사용됩니다.",
//     link: {
//         type: "link",
//         title: "블록체인 기술 확인하기",
//         url: "https://verify.firmachain.io"
//     }
// };

// export const SECURITY_CERTIFY = {
//     img: IMG_SECURITY_CERTIFI,
//     title: "계약의 모든 절차를\n빠짐없이 기록합니다.",
//     desc: "계약서 작성부터 체결까지 계약의 모든 과정을 \n기록하는 감사추적인증서를 제공합니다.\n감사추적인증서는 계약 참여자들의 정보\n(이름, 이메일, 전화번호, IP, 기기 등) 및\n모든 주요 행위(계약 작성, 서명, 파기 등)를 기록하여 \n강력한 전자 계약의 법적 효력을 보장합니다.",
//     link: {
//         // type: 'path',
//         // title: '감사추적인증서 샘플보기',
//         // url: '/security/pdf',
//         type: "link",
//         title: "감사추적인증서 샘플보기",
//         url: "감사추적인증서"
//     }
// };

// export const SECURITY_ISO = {
//     img: IMG_ISO_MARK,
//     title: "도뉴의 보안성은 ISO/IEC 27001이 보증합니다.",
//     title_mobile: "도뉴의 보안성은\nISO/IEC 27001이 보증합니다.",
//     desc: "도뉴는 정보보호 관리와 관련된 14개 분야와 114개 세부 통제항목에 대한 \n 엄격한 심사과정을 통과하여 국제 표준 기준으로 인정받은 보안 시스템으로 \n 여러분의 계약과 개인정보를 안전하게 관리합니다.",
//     desc_mobile: "도뉴는 정보보호 관리와 관련된 \n 14개 분야와 114개 세부 통제항목에 대한 \n 엄격한 심사과정을 통과하여 국제 표준 기준으로 \n 인정받은 보안 시스템으로 여러분의 계약과 \n 개인정보를 안전하게 관리합니다.",
//     qna: {
//         question: "ISO/IEC 27001은 무엇인가요?",
//         answer: "국제표준화 기구(ISO) 및 국제 전기기술위원회(IEC)에서 인증하는 \n 정보보호 관리체계에 대한 국제 표준으로, \n 세계 정보 보호 분야에서 가장 권위 있는 인증 제도입니다.",
//         answer_mobile: "국제표준화 기구(ISO) 및 \n 국제 전기기술위원회(IEC)에서 인증하는 \n 정보보호 관리체계에 대한 국제 표준으로, \n 세계 정보 보호 분야에서 가장 권위 있는 \n 인증 제도입니다."
//     }
// };

import { IMG_ISO_MARK, IMG_SECURITY_AWS, IMG_SECURITY_CERTIFI, IMG_SECURITY_DOC, IMG_TOP_BANNER_SECURITY, IMG_TOP_BANNER_SECURITY_MOBILE } from "../media/images";

// Security

export const SECURITY_TITLE = {
    img: IMG_TOP_BANNER_SECURITY,
    img_mobile: IMG_TOP_BANNER_SECURITY_MOBILE,
    title: "SECURITY_TITLE_TITLE",
    title_mobile: "SECURITY_TITLE_TITLE_MOBILE",
    desc: "SECURITY_TITLE_DESC"
};

export const SECURITY_AWS = {
    img: IMG_SECURITY_AWS,
    title: "SECURITY_AWS_TITLE",
    desc: "SECURITY_AWS_DESC",
    desc_mobile: "SECURITY_AWS_DESC_MOBILE"
};

export const SECURITY_AUTHENTIC = {
    img: IMG_SECURITY_DOC,
    title: "SECURITY_AUTHENTIC_TITLE",
    desc: "SECURITY_AUTHENTIC_DESC",
    desc_mobile: "SECURITY_AUTHENTIC_DESC_MOBILE",
    link: {
        type: "link",
        title: "SECURITY_AUTHENTIC_LINK_TITLE",
        url: "https://verify.firmachain.io"
    }
};

export const SECURITY_CERTIFY = {
    img: IMG_SECURITY_CERTIFI,
    title: "SECURITY_CERTIFY_TITLE",
    desc: "SECURITY_CERTIFY_DESC",
    desc_mobile: "SECURITY_CERTIFY_DESC_MOBILE",
    link: {
        type: "link",
        title: "SECURITY_CERTIFY_LINK_TITLE",
        url: "감사추적인증서"
    }
};

export const SECURITY_ISO = {
    img: IMG_ISO_MARK,
    title: "SECURITY_ISO_TITLE",
    title_mobile: "SECURITY_ISO_TITLE_MOBILE",
    desc: "SECURITY_ISO_DESC",
    desc_mobile: "SECURITY_ISO_DESC_MOBILE",
    qna: {
        question: "SECURITY_ISO_QNA_QUESTION",
        answer: "SECURITY_ISO_QNA_ANSWER",
        answer_mobile: "SECURITY_ISO_QNA_ANSWER_MOBILE"
    }
};
