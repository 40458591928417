import React from "react";
import DescriptionWidhImage from "../parts/description/descriptionWithImage";
import ImageTitle from "../parts/imageTitle";
import ISO from "./iso";

const Security = ({ title, iso, aws, auth, certify }) => {
    return (
        <>
            <ImageTitle data={title} />
            <ISO data={iso} imgWidth={"220px"} bgColor={"#E9ECF7"} />
            <DescriptionWidhImage data={aws} imgWidth={"540px"} reverse />
            <DescriptionWidhImage data={auth} padding={"110px 0"} imgWidth={"480px"} bgColor={"#f8f8f8"} />
            <DescriptionWidhImage data={certify} padding={"110px 0"} imgWidth={"480px"} reverse />
        </>
    );
};

export default Security;
